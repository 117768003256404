<template>
   <section class="h-auto rankup-bg" id="rankuprewards">
         <div class="flex lg:hidden justify-end item-center lg:pr-40 2xl:pr-80 lg:pb-14">
            <div class="flex flex-col justify-center items-center">
                <img src="../assets/rank-up-title.png" class="px-4 pt-10 md:pt-14 md:px-6" alt="rank-up-title">
                <a href="https://roobet.com/?ref=shibbbb" target="_blank">
                    <img src="../assets/rank-desc.png" alt="rank-desc">
                </a>
            </div>
        </div>
        <div class="flex flex-col-reverse lg:flex-row justify-center items-center lg:pt-24 lg:px-20 gap-8 lg:gap-24">
            <div class="flex flex-col-reverse lg:flex-col justify-center items-center lg:gap-8">
                <img src="../assets/silver-3.png" alt="silver-3">
                <img src="../assets/gold-2.png" alt="gold-2">
                <img src="../assets/gold-3.png" alt="gold-3">
            </div>
            <div class="flex flex-col-reverse lg:flex-col justify-center items-center lg:gap-8">
                <img src="../assets/gold-4.png" class="px-8 lg:px-0" alt="gold-4">
                <img src="../assets/emerald-1.png" class="px-8 lg:px-0" alt="emerald-1">
            </div>
            <div class="flex flex-col justify-center items-center lg:gap-8">
                <img src="../assets/emerald-2.png" class="px-6 lg:px-0" alt="emerald-2">
            </div>
        </div>
        <div class="hidden lg:flex justify-end item-center lg:pr-40 2xl:pr-80 lg:pb-14">
            <div class="flex flex-col justify-center items-center">
                <img src="../assets/rank-up-title.png" alt="rank-up-title">
                <a href="https://roobet.com/?ref=shibbbb" target="_blank">
                    <img src="../assets/rank-desc.png" alt="rank-desc">
                </a>
            </div>
        </div>
   </section>
</template>

<script>
export default {
    data () {
        return {

        }
    }
}
</script>

<style scoped>
.rankup-bg {
  background-image: url('../assets/rankup-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>