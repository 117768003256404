<template>
  <!-- Main section start -->
  <section class="h-screen lg:h-screen main-bg" id="main">
      <div class="flex-col pt-24 md:pt-32 p-8 lg:pt-20 2xl:pt-44 lg:px-16 2xl:px-56 hidden md:flex">
          <div class="flex justify-center lg:justify-start">
            <a href="https://roobet.com/?ref=shibbbb" target="_blank">
             <img v-motion-slide-visible-left src="../assets/shiibbbster.png" alt="shiibbbster">
            </a>
          </div>
          <div class="flex justify-center lg:-mt-16">
            <a href="https://roobet.com/?ref=shibbbb" target="_blank">
             <img v-motion-pop-visible src="../assets/x.png" alt="x">
            </a>
          </div>
          <div class="flex justify-center lg:justify-end lg:-mt-20 lg:mr-32">
            <a href="https://roobet.com/?ref=shibbbb" target="_blank">
             <img v-motion-slide-visible-right src="../assets/roobet.png" alt="roobet">
            </a>
          </div>
          <div class="flex justify-center align-bottom mt-14 md:mt-0 2xl:mt-16">
            <a href="https://roobet.com/?ref=shibbbb" target="_blank">
              <img v-motion-pop-visible src="../assets/banner.png" class="lg:w-[700px] 2xl:w-[850px]" alt="banner">
            </a>
          </div>
      </div>
      <div class="flex flex-col pt-24 md:pt-32 p-8 lg:pt-20 2xl:pt-44 lg:px-16 2xl:px-56 md:hidden">
          <div class="flex justify-center lg:justify-start">
             <img v-motion-pop-visible src="../assets/shiibbbster.png" alt="shiibbbster">
          </div>
          <div class="flex justify-center lg:-mt-16">
             <img v-motion-pop-visible src="../assets/x.png" alt="x">
          </div>
          <div class="flex justify-center lg:justify-end lg:-mt-20 lg:mr-32">
             <img v-motion-pop-visible src="../assets/roobet.png" alt="roobet">
          </div>
          <div class="flex justify-center align-bottom mt-14 md:mt-0 2xl:mt-16">
              <img v-motion-pop-visible src="../assets/banner.png" class="lg:w-[700px] 2xl:w-[850px]" alt="banner">
          </div>
      </div>
      <!-- <div class="flex flex-col lg:flex-row justify-center items-center pt-24 md:pt-32 p-8 lg:p-0 gap-10 lg:gap-24 2xl:pt-44">
          <div class="flex flex-col px-0 lg:px-0">
                <img v-motion-slide-visible-left src="../assets/join-roobet.png" class="w-[95%]" alt="sign-up">                         
              <div class="flex flex-col justify-start md:-ml-10 lg:-ml-12 lg:mt-4">
                <img v-motion-slide-visible-left src="../assets/use-code.png" class="w-[400px]" alt="use-code">
                <a href="https://roobet.com/?ref=kickballyboy" target="_blank">
                  <img v-motion-slide-visible-left src="../assets/kickballyboy.png" class="w-[700px] -mt-6 lg:-mt-14" alt="kickballyboy">
                </a>
              </div>
          </div>
          <div>
              <img v-motion-pop-visible src="../assets/ballyboy-logo.png" alt="roobet-logo">
          </div>
      </div> -->
  </section>

  <LeaderboardSection/>
  <RankUpSection/>

  <section class="h-auto vip-bg">
      <div class="flex justify-center pt-8 md:pt-10 lg:pt-20 px-5">
          <img src="../assets/vip-title.png" alt="title">
      </div>
      <div class="flex flex-col justify-start py-8 md:py-10 lg:py-20 px-5">
          <div class="text-left lg:px-14 uppercase">
            <span class="text-lg text-white font-bold">if you are VIP, or loyalty and want to make a switch over to an account under my code, we can make that happen!</span>
            <br><br>
            <span class="text-lg text-white font-semibold">What are VIP transfers?</span>
            <br>
            <span class="text-base text-white">• VIP transfer is a term commonly used in the realm of gambling websites like Roobet. It refers to a process where users are granted special privileges or bonuses upon becoming VIP players.</span>
            <br><br>
            <span class="text-lg text-white font-semibold">How do VIP transfers to Roobet work?</span>
            <br>
            <span class="text-base text-white">• Play on Roobet and over a 3 week period of enjoying Roobet's generous bonuses, 
              Roobet evaluates players for potential VIP status based on their activity and engagement. If deemed worthy, selected users will receive personal contact from a VIP manager, 
              who will provide details about Roobet's VIP program and extend an invitation to join.
            </span>
            <br><br>
            <span class="text-lg text-white font-semibold">What are the requirements?</span>
            <br>
            <span class="text-base text-white">• You must have a Roobet account under code 'shibbbb'.</span>
            <br>
            <span class="text-base text-white">• You must have VIP on another valid gambling website.</span>
            <br><br>
            <span class="text-lg text-white font-semibold">Whats the purpose of transferring my VIP status to Roobet?</span>
            <br>
            <span class="text-base text-white">• Roobet provides amazing bonuses starting within your first 3 weeks of play and then continuing to progress once getting into VIP.</span>
            <br>
            <span class="text-base text-white">• You can compete in the 'shibbbb' Roobet leaderboard every month.</span>
            <br>
            <span class="text-base text-white">• Roobet provides the best rewards in the long run.</span>
          </div>
      </div>
  </section>

  <section class="h-auto shadow-inner social-bg" id="socials">
      <div class="flex flex-col justify-center pt-14 md:pt-14 lg:pt-14">
          <div class="flex justify-center">
              <img v-motion-pop-visible src="../assets/socials-title.png" alt="socials-title">
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center pb-14 lg:gap-24  md:mt-4 lg:mt-8 md:pb-24 lg:pb-14">
             <div class="px-6">
                <img v-motion-slide-visible-bottom src="../assets/logo.png" class="w-[300px]" alt="logo">
             </div>
             <div v-motion-pop-visible class="flex px-4 md:px-14 bg-gradient-to-r from-[#D93105] to-[#8A007C] rounded-3xl" style="box-shadow: inset 0 0 15px black">
              <div v-motion-pop-visible class="flex flex-wrap justify-center items-center mx-6 my-10 md:my-16 gap-14 lg:gap-18 p-8 md:rounded-full bg-gradient-to-r from-[#D93105] to-[#8A007C]" style="box-shadow: inset 0 0 15px black">
                  <a href="https://discord.com/invite/j6spgdDmt8" target="_blank">
                    <img v-motion-slide-visible-bottom src="../assets/discordlogo.png" alt="discord">
                  </a>
                  <a href="https://www.instagram.com/shiibbbster?igsh=MzRlODBiNWFlZA==" target="_blank">
                    <img v-motion-slide-visible-bottom src="../assets/instagram.png" alt="instagram">
                  </a>
                  <a href="https://x.com/slotswithshib?s=21" target="_blank">
                    <img v-motion-slide-visible-bottom src="../assets/twitter.png" alt="twitter">
                  </a>
                  <a href="https://youtube.com/@shiibbbster?si=VOu7aQ4pv8DUW-3K" target="_blank">
                    <img v-motion-slide-visible-bottom src="../assets/youtube.png" alt="youtube">
                  </a>
                  <a href="https://kick.com/shiibbbster" target="_blank">
                    <img v-motion-slide-visible-bottom src="../assets/kick.png" alt="kick">
                  </a>
              </div>
            </div>
          </div>
      </div>
  </section>
  
  <ChallengesSection/>
  
</template>

<script>
import { defineComponent } from 'vue';
import LeaderboardSection from '@/sections/LeaderboardSection.vue';
import ChallengesSection from '@/sections/ChallengesSection.vue';
import RankUpSection from '@/sections/RankUpSection.vue';

export default defineComponent({
  components: {
      LeaderboardSection,
      ChallengesSection,
      RankUpSection
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
  },
});
</script>

<style scope>
body {
  background-color: #0B0B0C;
}
.main-bg {
  background-image: url('../assets/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.social-bg {
  background-image: url('../assets/socials-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.vip-bg{
  background-image: url('../assets/vip-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
